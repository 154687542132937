<script>
import DatePicker from "vue2-datepicker";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import * as moment from "moment";
DatePicker.methods.displayPopup = function () {
  this.position = {
    left: 0,
    top: "100%",
  };
};
/**
 * Form Validation component
 */
export default {
  components: { DatePicker, Multiselect },
  data() {
    return {
      form: {
        code: null,
        type: { name: "Fixed", value: 'fixed' },
        amount: null, 
        quantity: null,
        dateExpire: null,
        description: null,
      },
      typesubmit: false,
      isError: false,
      errorMessage: null,
      options: [
        { name: "Fixed", value: 'fixed' },
        { name: "Percentage", value: 'percentage' },
      ],
      isTypeNotSelected: false,
      isDateExpireNotSelected: false
    };
  },
  validations: {
    form: {
      code: { required },
      type: { required },
      amount: { required },
      dateExpire: { required },
      quantity: { required },
    },
  },
  mounted() {
    this.getVoucherDetail()
  },
  methods: {
    getVoucherDetail() {
      this.$axios.get(`voucher/${this.$route.params.id}`)
      .then( async (response) => {
        console.log(response)
        let res = response.data.result
        
        let dateExpire  = moment(res.voucher.expiredAt).format("YYYY-MM-DD")
        let amount = (res.voucher.type == 'percentage')? res.voucher.amount : this.convertToRupiah(res.voucher.amount, false)
        this.form = {
          code: res.voucher.code,
          type: (res.voucher.type == 'fixed')? { name: "Fixed", value: 'fixed' } : { name: "Percentage", value: 'percentage' },
          amount: amount,
          quantity: res.voucher.quantity,
          description: res.voucher.description, 
          dateExpire: new Date(dateExpire),
        }
      })
    },
    formatRupiah() {
      const angka = this.convertToAngka(this.form.amount)
      const rupaiah = this.convertToRupiah(angka, false)
      this.form.amount = rupaiah
    },
    onSubmit() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true;
        this.errorMessage = "please fill the form with valid data";
      } else {
        this.isTypeNotSelected = this.form.type === null ? true : false; 
        this.isDateExpireNotSelected = this.form.dateBegin === null ? true : false;

        if (this.isTypeNotSelected || this.isDateExpireNotSelected) {
          return false;
        }

        // do your submit logic here
        let amount;
        if (this.form.type.value == 'fixed') {
          amount = this.convertToAngka(this.form.amount);
        } else {
          amount = this.form.amount
        }

        let param = new FormData();
        param.append("code", this.form.code);
        param.append("type", this.form.type.value);
        param.append("amount", amount);
        param.append("quantity", this.form.quantity);
        param.append("description", (this.form.description)? this.form.description : ""); 
        param.append("expire",moment(this.form.dateExpire).format("YYYY-MM-DD"));

        this.$axios
          .put(`/voucher/${this.$route.params.id}`, param)
          .then((response) => {
            console.log(response)
            Swal.fire("Success", "Voucher Updated Successfully", "success");
            this.$router.push("/voucher");
          })
          .catch((e) => {
            if (e.response) {
              this.isError = true;
              this.errorMessage = e.response.data.message;
            }
          });
      }
    },
    notBeforeToday(date) {
      return date < new Date(new Date());
    }
  },
 
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <b-alert
            v-model="isError"
            variant="danger"
            class="mt-3"
            dismissible
            >{{ errorMessage }}</b-alert
          >
          <form action="#" @submit.prevent="onSubmit">
            <div class="form-group">
              <label>Code</label>
              <input
                v-model="form.code"
                type="text"
                class="form-control"
                name="code"
                :class="{ 'is-invalid': typesubmit && $v.form.code.$error }"
              />
              <div
                v-if="typesubmit && $v.form.code.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.form.code.required"
                  >This value is required.</span
                >
              </div>
            </div>
            <div class="form-group">
              <label>Type</label>
              <multiselect
                :class="{ 'is-invalid': isTypeNotSelected }"
                v-model="form.type"
                deselect-label="Can't remove this value"
                track-by="name"
                label="name"
                placeholder="Select one"
                :options="options"
                :searchable="false"
                :allow-empty="false"
                @select="form.amount = null"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <strong>{{ option.name }}</strong>
                </template>
              </multiselect>
              <div v-if="isTypeNotSelected" class="invalid-feedback">
                <span>This value is required.</span>
              </div>
            </div>
            <div class="form-group">
              <label>Amount</label>
              <template  v-if="form.type.value == 'fixed'">
                <b-input-group prepend="Rp.">
                  <input
                    v-model="form.amount"
                    type="text"
                    class="form-control"
                    name="amount"
                    @keyup="formatRupiah"
                    :class="{
                      'is-invalid': typesubmit && $v.form.amount.$error,
                    }"
                  />
                  <div
                    v-if="typesubmit && $v.form.amount.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.amount.required"
                      >This value is required.</span
                    >
                  </div>
                </b-input-group>
              </template>
              <template v-else>
                <b-input-group append="%">
                  <input
                    v-model="form.amount"
                    type="number"
                    min="1"
                    class="form-control"
                    name="amount"
                    :class="{ 'is-invalid': typesubmit && $v.form.amount.$error }"
                  />
                </b-input-group>
              </template>
              <div v-if="typesubmit && $v.form.amount.$error" class="invalid-feedback">
                <span v-if="!$v.form.amount.required">This value is required.</span>
              </div>
            </div>
            <div class="form-group">
              <label>Quantity:</label>
              <input
                v-model="form.quantity"
                type="text"
                class="form-control"
                name="quantity"
                :class="{ 'is-invalid': typesubmit && $v.form.quantity.$error }"
              />
              <div
                v-if="typesubmit && $v.form.quantity.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.form.quantity.required"
                  >This value is required.</span
                >
              </div>
            </div>
            <div class="form-group">
              <label>Date Expire</label>
              <br />
              <date-picker
                v-model="form.dateExpire"
                format="DD MMMM YYYY"
                name="date"
                :first-day-of-week="1"
                :disabled-date="notBeforeToday"
                lang="en"
                placeholder="Select Date"
                :class="{ 'is-invalid': isDateExpireNotSelected }"
              ></date-picker>
              <div v-if="isDateExpireNotSelected" class="invalid-feedback">
                <span>This value is required.</span>
              </div>
            </div>
              <div class="form-group">
                <label>Description</label>
                <div>
                  <textarea
                    v-model="form.description"
                    name="description"
                    class="form-control"
                  ></textarea>
                </div>
              </div>
            

            <div class="form-group mb-0">
              <div>
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
        <!-- end card-body -->
      </div>
    </div>
    <!-- end col-->
  </div>
</template>